/**
 * Development Environment
 */
const baseUrl = `https://api.onboardr-dev.timbergrove.com/api`;
// const baseUrl = `http://localhost:3000/api`;

export const environment = {
  production: false,
  apiRoutes: {
    companies: `${baseUrl}/companies/`,
    maps: `${baseUrl}/maps/`,
    programs: `${baseUrl}/programs/`,
    routeMaps: `${baseUrl}/route-maps/`,
    schemes: `${baseUrl}/schemes/`,
    inductionLocations: `${baseUrl}/induction-locations/`,
    users: `${baseUrl}/users/`,
    auth: `${baseUrl}/auth/`,
    addresses: `${baseUrl}/addresses/`,
    constraints: `${baseUrl}/constraints/`,
    contacts: `${baseUrl}/contacts/`,
    origins: `${baseUrl}/origins/`,
    programOrigins: `${baseUrl}/program-origins/`,
    routes: `${baseUrl}/routes/`,
    elements: `${baseUrl}/elements/`,
    elementValues: `${baseUrl}/element-values/`,
    schemeTypes: `${baseUrl}/scheme-types/`,
  },
};
